/**
 * 整个项目mock api的管理
 */

import request from "./request.js";


export default {
    // getHomeTableData(params) {
    //     return request({
    //         url: '/home/getData',
    //         method: 'get',
    //         data: params,
    //         mock: true
    //     })
    // },
    getHomeTableData(params) {
        return request({
            url: '/api/vue/courses/',
            method: 'get',
            data: params,
            mock: false
        })
    },
    getUserData(params) {
        return request({
            url: '/auth/me',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getOrderData(params) {
        return request({
            url: '/auth/orders',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getTotalOrderData(params) {
        return request({
            url: '/auth/myorderstotal',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getMGoodsData(params) {
        return request({
            url: '/auth/products',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getMgoodsDetailData(params) {
        return request({
            url: '/auth/product',
            method: 'post',
            data: params,
            mock: false
        })
    },
    Add2My(params) {
        return request({
            url: '/auth/addmygoods',
            method: 'post',
            data: params,
            mock: false
        })
    },
    ModiPrice(params) {
        return request({
            url: '/auth/mygoodprice',
            method: 'post',
            data: params,
            mock: false
        })
    },
    ModiStock(params) {
        return request({
            url: '/auth/mygoodnumber',
            method: 'post',
            data: params,
            mock: false
        })
    },
    //商品上下架
    MyGoodsUpDown(params) {
        return request({
            url: '/auth/mygoodonsale',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getMyGoodsData(params) {
        return request({
            url: '/auth/mygoods',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getEchartData(params) {
        return request({
            url: '/home/getEchartData',
            method: 'get',
            data: params,
            mock: false
        })
    },
    getAsideMenu(params) {
        return request({
            url: '/home/getAsideMenu',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getMyOrderData(params) {
        return request({
            url: '/auth/myorders',
            method: 'post',
            data: params,
            mock: false
        })
    },
    MyOrderData(params) {
        return request({
            url: '/auth/myorder',
            method: 'post',
            data: params,
            mock: false
        })
    },
    MyOrderbyNoData(params) {
        return request({
            url: '/auth/myorderbyno',
            method: 'post',
            data: params,
            mock: false
        })
    },
    getBillsData(params) {
        return request({
            url: '/auth/mybills',
            method: 'post',
            data: params,
            mock: false
        })
    },
    BillData(params) {
        return request({
            url: '/auth/mybill',
            method: 'post',
            data: params,
            mock: false
        })
    },
    batEditbybarCode(params) {
        return request({
            url: '/auth/mygoodpricebygoodssn',
            method: 'post',
            data: params,
            mock: false
        })
    },
    ModiPass(params) {
        return request({
            url: '/auth/modipass',
            method: 'post',
            data: params,
            mock: false
        })
    },
    login(params) {
        return request({
            url: '/auth/login',
            method: 'post',
            data: params,
            mock: false
        })
    },
    refreshToken(params) {
        return request({
            url: '/auth/refresh',
            method: 'post',
            data: params,
            mock: false
        })
    }
}