import { createRouter, createWebHashHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/home',
    component: () => import('../views/Main.vue'),
    // children: [],
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/Home.vue'),
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('../views/user/User.vue'),
      },
      {
        path: '/orders/orders',
        name: 'orders',
        component: () => import('../views/orders/Orders.vue'),
      },
      {
        path: '/orders/bills',
        name: 'bills',
        component: () => import('../views/orders/Bills.vue'),
      },
      {
        path: '/orders/order',
        name: 'order',
        component: () => import('../views/orders/Order.vue'),
      }
      ,
      {
        path: '/orders/dcprint',
        name: 'dcprint',
        component: () => import('../views/orders/DcPrint.vue'),
      },
      // {
      //   path: '/goods/Mgoods',
      //   name: 'mgoods',
      //   component: () => import('../views/goods/Mgoods.vue'),
      // },
      // {
      //   path: '/goods/Mygoods',
      //   name: 'mygoods',
      //   component: () => import('../views/goods/Mygoods.vue'),
      // },
      // //importexcel
      // {
      //   path: '/goods/importexcel',
      //   name: 'importexcel',
      //   component: () => import('../views/goods/ImportExcel.vue'),
      // },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('../views/login/Logout.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
