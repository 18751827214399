/**
 * 项目的环境配置
 */

// 这是vite的一种使用方式：https://cn.vitejs.dev/guide/env-and-mode.html#env-files
const env =  'dev'

const envConfig = {
    dev: {
        // baseApi: 'dev.example.com/api',
        baseApi: 'https://store.nypwn.com/api',
       
    },
    test: {
        baseApi: 'test.example.com/api',
        
    },
    prod: {
        baseApi: 'https://store.nypwn.com/api',
        
    }
}

export default {
    env,
    // mock: false, // 控制使用mock与否的总开关
    ...envConfig[env]
}